import { v4 as uuid } from 'uuid'
import { ADMIN_TAG, TRAINER_TAG, TEACHER_TAG } from '../constants.js'

const ROLE_ASSERTION_TYPE = 'application/json;type=role_assertion'
const ROLE_REQUEST_TYPE = 'application/json;type=role_request'

const rolePermissions = {
  admin:      { admin: true,  researcher: true,  teacher: true,  student: true },
  researcher: { admin: false, researcher: true,  teacher: true,  student: true },
  teacher:    { admin: false, researcher: false, teacher: true,  student: true },
  student:    { admin: false, researcher: false, teacher: false, student: true },
}

export default {
  scope: null,
  namespaced: true,
  state: () => ({
    assignments: {},
    requests: {}
  }),
  getters: {
    assignments: state => () => state.assignments,
    requests: state => () => state.requests,
    usersWithRole: state => role => (
      Object
        .entries(state.assignments)
        .filter(([_user, {role: r}]) => r === role)
        .map(([user]) => user)
    ),
    role: (state, getters, rootState) => (user=rootState.user) => {
      const assignment = state.assignments[user]
      return assignment ? assignment.role : 'student'
    },
    hasPermission: (_state, getters) => (user, permission) => {
      const role = getters.role(user)
      if (!rolePermissions[role]) return false
      else return !!rolePermissions[role][permission]
    },
    request: state => user => {
      if (state.requests[user]) return state.requests[user]
      return null
    }
  },
  mutations: {
    addRequest(state, { assignee, role, trainer, updated }) {
      state.requests[assignee] = { role, trainer, updated }
    },
    addAssignment(state, { assignee, role, assigner, updated }) {
      state.assignments[assignee] = { role, assigner, updated }
    },
    removeRequest(state, assignee) {
      delete state.requests[assignee]
    }
  },
  actions: {
    async load({ dispatch }) {
      await Promise.all([
        dispatch('loadAssignments'),
        dispatch('loadRequests')
      ])
    },
    async loadAssignments({ commit, getters, rootGetters }) {
      if (rootGetters.isThailandDomain) {
        await Promise.all(
          [
            ['admin', ADMIN_TAG],
            ['teacher', TEACHER_TAG]
          ].map(([role, tag]) => (
            Agent
              .query('taggings-for-tag', [rootGetters.tagPartition, tag], 'tags.knowlearning.systems')
              .then(result => result.forEach(({ contributor: assigner, target: assignee }) => {
                commit('addAssignment', { assigner, assignee, role })
              }))
          ))
        )
      }
      else {
        await (
          Agent
            .query('role-assignments')
            .then(assignments => {
              assignments.forEach(assignment => commit('addAssignment', assignment))
            })
        )
      }
      const { auth: { user } } = await Agent.environment()
      if (getters.role(user) !== 'student') {
        await storeUserInfo()
      }
    },
    async loadRequests({ commit }) {
      await (
        Agent
          .query('requested-roles')
          .then(async requests => {
            requests.forEach(request => commit('addRequest', request))
          })
      )
    },
    async removeRequest({ commit }, user) {
      commit('removeRequest', user)
    },
    async request({ dispatch }, { role, trainer }) {
      const metadata = await Agent.metadata('requested-role')
      if (metadata.active_type !== ROLE_REQUEST_TYPE) {
        metadata.active_type = ROLE_REQUEST_TYPE
      }
      const state = await Agent.state('requested-role')
      state.role = role
      state.trainer = trainer
      await storeUserInfo()
      await Agent.synced()
      await dispatch('loadRequests')
    },
    async assign({ dispatch }, { user, role }) {
      Agent.create({
        active_type: ROLE_ASSERTION_TYPE,
        active: { role, assignee: user }
      })
      await Agent.synced()
      await dispatch('loadAssignments')
    }
  }
}

async function storeUserInfo() {
  const { auth: { user, info: { name, picture } } } = await Agent.environment()
  const myUserInfo = await Agent.state('user-info')
  myUserInfo.name = name
  myUserInfo.picture = picture
}