export const CANDLI_SEQUENCES = {
  // Math underwater, on land & in the air!
  '1d764820-05c2-11ef-86c1-15e3e190b62a': [
    'c8c710aa7fee5af189791f64fc8270d6', // Chirpy's Adventure Level 1
    '0adb500fa86a5cc6b62ab7ca3680ec64', // Save Our Oceans Level 1
    '4ef94c7e64a95c27b188a00621053697', // Math Invaders
    '1248d7dda51b584eb1596ad3c13a90f5'  // Chirpy's Adventure Level 2
  ],
  // Leveling Up With Math
  '657e1210-05c2-11ef-8cb0-6d54e69f38f6': [
    '376229ad69aa520b9ba8c711c2dbdbb8', // Chirpy's Adventure Level 3
    'e86dfc597c04587b9348952bfdcb76b1', // Save Our Oceans Level 2
    '7a042193eea054b1b85925e074de9b15'  // Magical Mixology
  ],
  // Be a Scientist
  '768f3090-05c0-11ef-86c1-15e3e190b62a': [
    'd145b0ae50ad557ebb6e91931ec42ce8', // Mushroom Mayhem
    '943efee423d75be3bdf761f1e81c7e37', // Gravity Experimentation
    '7160574dc9ec52938f23011a4bcd698f'  // Paper Planes
  ],
  // Thai Leveling Up With Math
  'f93c2f40-0ee7-11ef-bbd8-13a3d43c1666': [
    "4a95faed0b105822adc1d90c96c4b645",
    "237d32dc23cc59e680ed47ec2e3a4fb5",
    "de6a2f6f709e57a1b3aa6358a5a8d0bb"
  ],
  // Thai Math underwater, on land & in the air!
  '87f37b90-0ee7-11ef-bbd8-13a3d43c1666': [
    "f4cb3e4fe72355f58e6b58d458d31205",
    "6f48841b58af5d8aa450ae5f9258f1ec",
    "5f4092d3956e53c98ccec3da0a2f3925",
    "e8d0ab2438765c1c9dd9962d10444a0c"
  ],
  // Thai Be a Scientist
  'b56c1100-0ee6-11ef-bbd8-13a3d43c1666': [
    "a1c31030facc58d688a07040e25771db",
    "93690465d42f52058c92404e329651be",
    "ba07adc0589f5f3a990f995cc54b8efd"
  ]
}

export const ADMIN_TAG = "36e1b060-ed49-11ee-be89-5b04faf266ea"
export const TEACHER_TAG = "49bf66a0-ed49-11ee-be89-5b04faf266ea"
export const TRAINER_TAG = "8ae541e0-ed49-11ee-be89-5b04faf266ea"
export const PILA_CONTENT_TAG = "1a53db50-e248-11ee-ab5f-07f4a7408770"
